.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.App-quiz {
  margin-top: 1em;
  font-size: 0.9em;
}

.App-quiz-question {
  display: flex;
}

fieldset {
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin-bottom: 20px;
}

.App-quiz-answer {
  width: 200px;
}

.App-quiz-answer > span {
  display: block;
  height: 3em;
}

hr {
  border-top: 1px solid #ddd;
}

.button {
  background: none;
  padding: 1em 2em;
  border: 1px solid #282c34;
  margin: 5px;
}

.button-submit {
  background-color: rgb(0, 208, 255);
}

.message-error {
  color: red;
  margin-bottom: 1em;
}

.message-color {
  margin-bottom: 1em;
}

.orange .App-header {
  background: #f08b19da;
  color: black;
}

.blue .App-header {
  background: #1964f0da;
  color: white;
}

.gold .App-header {
  background: gold;
  color: black;
}

.green .App-header {
  background: rgb(41, 170, 41);
  color: white;
}
